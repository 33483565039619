<!--上传页面-->
<template>
  <div :class="['commonUpload', edit ? 'commonUpload-pd' : '']">
    <div class="content common-block">
      <p class="title">{{ edit ? $t('请选择上传') : '' }}{{ name }}</p>
      <van-uploader
        accept="image/*"
        :disabled="!edit"
        :deletable="edit"
        :file-list="fileList"
        :preview-options="{closeable: true}"
        :before-read="beforeRead"
        :after-read="(file) => afterRead(file)"
        :before-delete="(file) => beforeDelete(file)"
        :max-count="edit ? 10 : fileList.length"
        @click-preview="onPreview">
      </van-uploader>
    </div>
    <div v-if="edit" class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t('返回') }}</van-button>
      <van-button class="btn submit" :loadding="btnLoading" type="submit" @click="onSubmit">{{ $t('提交') }}</van-button>
    </div>
  </div>
</template>
<script>
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
export default {
  data() {
    return {
      name: '',
      fileList: [],
      edit: true,
      code: '',
      btnLoading: false,
    }
  },
  beforeRouteLeave(to, from, next){
    localStorage.clear('templateFileInfo')
    next()
  },  
  computed: {
    language() {
      return window.navigator.language.toLocaleLowerCase().includes('en') ? 'en' : 'zh'
    }
  },
  mounted() {
    const templateFileInfo = JSON.parse(localStorage.getItem('templateFileInfo') || '{}')
    const { code, name, url } = templateFileInfo
    this.code = code
    this.edit = url ? false : true
    this.name = name
    if (url && url?.split(',').length > 0) {
      const urlList = url?.split(',')
      urlList.forEach(item => {
        this.fileList.push({
          url: item,
        })
      })
    }
    window.document.title = name
  },
  methods: {
    onPreview({ url }){
      if (this.isPdf(url)){
        this.openPdf(url)
      }
    },
    isPdf(fileUrl) {
      const url = fileUrl.split('?')[0]
      if (url){
        return url.substring(url.lastIndexOf('.') + 1) === 'pdf'
      }
      return false
    },
    openPdf(fileUrl){
      if (this.$isMobile){
        window.location.href = fileUrl
      } else {
        window.open(fileUrl)
      }
    },
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      if (file.size > 30 * 1024 * 1024) {
        this.$toast.fail(this.$t('请上传不超过30MB的图片'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      loading.showLoading()
      uploadFile(file.file).then(({ url }) => {
        loading.hideLoading()
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
        }
        this.fileList.push(file)
      }).catch(() => {
        loading.hideLoading()
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    goBack() {
      this.$router.go(-1)
    },
    async onSubmit() {
      if (this.fileList.length == 0) {
        this.$toast(this.$t('请上传文件'))
        return false
      }
      if (this.btnLoading) return false
      this.btnLoading = true
      loading.showLoading()
      const params = {
        orderId: this.$route.query.id,
        templateFile: [
          {
            code: this.code,
            templateUrl: this.fileList.map(({ cosUrl }) => { return cosUrl }).join(',')
          }
        ]
      }
      const res = await deliveryServices.uploadTemplateFile(params)
      loading.hideLoading()
      this.$toast.success(this.$t('操作成功'))
      setTimeout(() => {
        this.$router.go(-1)
      }, 1500)
    }
  }
}
</script>
<style lang="less" scoped>
.commonUpload{
  padding: 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  
  &.commonUpload-pd{
    padding-bottom: 96px;
  }
  .content{
    background: #fff;
    padding: 12px;
    .title{
      margin-bottom: 16px;
    }
    /deep/ .van-uploader {
      .van-uploader__wrapper--disabled{
        opacity: 1;
      }
    }
  }
}
</style>